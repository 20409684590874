<template>
    <section class="px-5 pt-3">
        <div class="row mx-0 mb-4">
            <div class="col-5 pl-0">
                <div class="row mx-0">
                    <div class="col px-0">
                        <p class="text-black f-20 f-500 ">
                            {{ editar ? 'Editar' : 'Crear' }}  vigilante
                        </p>
                    </div>
                    <template v-if="editar">
                        <el-popover
                        placement="bottom"
                        popper-class="p-2"
                        trigger="click"
                        >
                            <div class="row mx-0 item-option align-items-center px-2 cr-pointer br-4" @click="modalEliminarVigilante">
                                Eliminar
                            </div>
                            <div slot="reference" class="btn-action wh-32 cr-pointer">
                                <i class="icon-vertical-points f-15 text-black" />
                            </div>
                        </el-popover>
                    </template>
                </div>
            </div>
        </div>
        <ValidationObserver ref="form">
            <div class="row mx-0 my-2 mb-4">
                <div class="col-5 pl-0">
                    <p class="label">
                        Nombre
                    </p>
                    <ValidationProvider v-slot="{errors}" rules="required|max:20" name="Nombre">
                        <el-input v-model="vigilante.nombre" show-word-limit maxlength="20" placeholder="Nombre" class="w-100" size="small" />
                        <span class="text-danger f-10">{{ errors[0] }}</span>
                    </ValidationProvider>
                </div>
            </div>
            <div class="row mx-0 my-2 mb-4">
                <div class="col-5 pl-0">
                    <p class="label">
                        Apellido
                    </p>
                    <ValidationProvider v-slot="{errors}" rules="required|max:20" name="Apellido">
                        <el-input v-model="vigilante.apellido" show-word-limit maxlength="20" placeholder="Apellido" class="w-100" size="small" />
                        <span class="text-danger f-10">{{ errors[0] }}</span>
                    </ValidationProvider>
                </div>
            </div>
            <div class="row mx-0 my-2 mb-4">
                <div class="col-5 pl-0">
                    <p class="label">
                        Número telefónico
                    </p>
                    <ValidationProvider v-slot="{errors}" rules="required|max:20|numeric" name="Telefono">
                        <el-input v-model="vigilante.telefono" show-word-limit maxlength="14" placeholder="Número telefónico" class="w-100" size="small" />
                        <span class="text-danger f-10">{{ errors[0] }}</span>
                    </ValidationProvider>
                </div>
            </div>
            <div class="row mx-0 my-2 mb-4">
                <div class="col-5 pl-0">
                    <p class="label">
                        Contraseña
                    </p>
                    <ValidationProvider v-slot="{errors}" :rules="{required:!editar,min:6,max:15}" name="Contraseña">
                        <el-input v-model="vigilante.contrasena" type="password" show-word-limit maxlength="15" show-password placeholder="*******" class="w-100" size="small" />
                        <span class="text-danger f-10">{{ errors[0] }}</span>
                    </ValidationProvider>
                </div>
            </div>
            <div class="row mx-0 my-2">
                <div class="col-5 pl-0">
                    <p class="label">
                        Repetir contraseña
                    </p>
                    <ValidationProvider v-slot="{errors}" :rules="{required:!editar,min:6,max:15,confirmed:confirmedPassword()}" name="contraseña">
                        <el-input v-model="repetir" type="password" show-word-limit maxlength="15" show-password placeholder="*******" class="w-100" size="small" />
                        <span class="text-danger f-10">{{ errors[0] }}</span>
                    </ValidationProvider>
                </div>
            </div>
            <div class="row mx-0 my-3 d-middle">
                <el-checkbox v-model="vigilante.responsable" :true-label="1" :false-label="0" class="check-dark" />
                <div class="col-auto f-14">
                    Vigilante responsable
                    <el-tooltip placement="bottom" content="Añadir pregunta" effect="light">
                        <template #content>
                            <p class="f-14 text-general">Número de contacto disponible que tendrá los residentes para comunicarse con portería desde la app. Solo puede haber un vigilante responsable.</p>
                        </template>
                        <i class="icon-help-circle-outline f-18 text-black" />
                    </el-tooltip>
                </div>
            </div>
        </ValidationObserver>
        <div class="row mx-0 mt-4">
            <div v-if="editar" class="btn-general px-3 cr-pointer br-5" @click="editarVigilante">
                <span v-loading="loading">Guardar</span>
            </div>
            <div v-else class="btn-general px-3 cr-pointer br-5" @click="guardarVigilante">
                <span v-loading="loading">Guardar</span>
            </div>
            <div class="px-3 cr-pointer ml-3 border br-5" @click="irAtras">
                <span v-loading="loading">Cancelar</span>
            </div>
        </div>
        <!-- Partials -->
        <modal-eliminar ref="modalEliminarVigilante" titulo="Eliminar Vigilante" mensaje="¿Desea eliminar este vigilante?" sub-mensaje="No podrá acceder nuevamente" @eliminar="eliminarVigilante" />
    </section>
</template>

<script>
import Service from '~/services/vigilantes/vigilantes'

export default {
    data(){
        return {
            repetir: "",
            vigilante:{
                id:null,
                nombre:'',
                apellido:'',
                telefono:'',
                contrasena:'',
                responsable:0,
            },
            loading:false,
        }
    },

    computed:{
        editar(){
            return this.$route.params.idVigilante;
        },
    },

    mounted(){
        if (this.editar){
            this.detalleVigilante()
        }
    },

    methods:{
        async detalleVigilante(){
            try {
                if(!this.$route.params.idVigilante)return;

                this.loading = true;

                const {data} = await Service.getDetalleVigilante(this.$route.params.idVigilante);
                this.vigilante = {
                    id: data.data.id,
                    nombre: data.data.nombre,
                    apellido: data.data.apellido,
                    telefono: data.data.telefono,
                    responsable: data.data.responsable,
                }

                this.loading = false;
            } catch (e){
                this.errorCatch(e);
                this.loading = false;
            }
        },

        async guardarVigilante(){
            try {
                let valid = await this.$refs.form.validate();
                if(!valid) return;

                this.loading = true;

                const payload = {
                    nombre: this.vigilante.nombre,
                    apellido: this.vigilante.apellido,
                    telefono: this.vigilante.telefono,
                    contrasena: this.vigilante.contrasena,
                    responsable: this.vigilante.responsable,
                }

                const {data} = await Service.postVigilante(payload)
                if(data.success){
                    this.notificacion( 'Guardar', data.mensaje, 'success');
                }

                this.loading = false;
                this.$router.replace({name:'vigilantes'});
            } catch (e){
                this.errorCatch(e);
                this.loading=false
            }
        },

        async editarVigilante(){
            try {
                let valid = await this.$refs.form.validate();
                if(!valid) return;

                this.loading = true;
                const payload = {
                    nombre: this.vigilante.nombre,
                    apellido: this.vigilante.apellido,
                    telefono: this.vigilante.telefono,
                    contrasena: this.vigilante.contrasena,
                    responsable: this.vigilante.responsable,
                }

                const {data} = await Service.putVigilante(this.vigilante.id, payload);

                if(data.success){
                    this.$notify({ title: 'Guardar',message: data.mensaje,type: 'success' });
                }

                this.loading = false;
                this.$router.replace({name:'vigilantes'});
            } catch (e){
                this.errorCatch(e);
                this.loading = false;
            }
        },

        modalEliminarVigilante(){
            this.$refs.modalEliminarVigilante.toggle();
        },

        async eliminarVigilante(){
            try {
                this.loading = true;

                await Service.deleteVigilante(this.vigilante.id);

                this.notificacion('','Vigilante eliminado correctamente','success');
                this.$refs.modalEliminarVigilante.toggle();
                this.loading = false;
                this.$router.push({ name: 'vigilantes' });
            } catch (error){
                this.errorCatch(e);
                this.loading = false;
            }
        },

        irAtras(){
            this.$router.push({ name: 'vigilantes' });
        },

        confirmedPassword(){
            return this.vigilante.contrasena != this.repetir ? true : false;
        }
    }
}
</script>
<style lang="scss" scoped>
.label{
    color: #5F6D84;
    font-size: 12px;
    padding-left: 15px;
}

.btn-action{
    width: 32px;
    height: 32px;
    border-radius: 4px;
    border: 1px solid #CFD7DF;
    background-image: linear-gradient(to right, #FFFFFF, #F3F5F7);
    display: flex;
    align-items: center;
    justify-content: center;
}

.item-option{
    height: 32px;
    color: #000000;
    &:hover{
        background-color: #DBDBDB !important;
    }
}
</style>